import Vue from 'vue'
import VueRouter from 'vue-router'
//import Pocetna from '../views/Pocetna.vue'
//import Onama from '../views/Onama.vue'
//import Kontakt from '../views/Kontakt.vue'
//import Autobuska from '../views/Autobuska_stanica.vue'
// import Galerija from '../views/Galerija.vue'
//import Medjunarodni_saobracaj from '../views/Medjunarodni_saobracaj.vue'
//import Prigradski_saobracaj from '../views/Prigradski_saobracaj.vue'
//import Vanlinijski_saobracaj from '../views/Vanlinijski_saobracaj.vue'

Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    name: 'Pocetna',
    //component: Pocetna,
    component: () => import('../views/Pocetna.vue'),
    meta: {
      title: 'Domuztrans Početna stranica',
      metaTags: [
        {
          name: 'description',
          content: 'Domuztrans vrši putnika u međunarodnom, prigradskom i vanlinijskom saobraćaju. Međunarondni prevoz na liniji Banja Vućica, Teslić, Prnjavor, Kopar'
        },
        {
          property: 'keywords',
          content: 'Domuztrans, Domuz-trans, međunarodni saobaćaj, prigradski saobračaj vanlijijski saobraćaj, Kremna, Prnjavor, Kopar, prevoz putnika'
        }
      ]
    }
  },
  {
    path: '/onama',
    name: 'Onama',
    component: () => import('../views/Onama.vue'),
    
    meta: {
      title: 'Domuztrans  O nama',
      metaTags: [
        {
          name: 'description',
          content: 'Domuztrans doo registrovano 1992 u oblasti saobraćaja sa sjedišem Kremna bb Prnjavor, posjeduje 10 autobusa'
        },
        {
          property: 'keywords',
          content: 'Domuztrans, Domuz-trans, međunarodni saobaćaj, vanlijijski saobraćaj, Kremna, Prnjavor, Kulaši, Kopar, prevoz putnika'
        }
      ]
    }
  },   
  {
    path: '/usluge_prevoza/medjunarodni_saobracaj',
    name: 'Medjunarodni_saobracaj', 
    component: () => import('../views/Medjunarodni_saobracaj.vue'),

    meta: {
      title: 'Domuztrans Međunardni saobraćaj',
      metaTags: [
        {
          name: 'description',
          content: 'Prevoz putnika na međunarodnoj liniji  Teslić, Prnjavor - Koper'
        },
        {
          property: 'keywords',
          content: 'Domuztrans, Domuz-trans, međunarodni saobaćaj, Prnjavor, Teslić, Kopar, prevoz putnika'
        }
      ]
    }
  },
  
  {
    path: '/usluge_prevoza/vanlinijski_saobracaj',
    name: 'Vanlinijski_saobracaj', 
    component: () => import('../views/Vanlinijski_saobracaj.vue'),

    meta: {
      title: 'Domuztrans Vanlinijski saobraćaj',
      metaTags: [
        {
          name: 'description',
          content: 'Domuztrans vrši prevoz putnika i u vanlinijskom saobraćaju: eskurzije, izleti, šoping ture, sportska takmičenja'
        },
        {
          property: 'keywords',
          content: 'Domuztrans, vanlijijski saobraćaj, eskurzije, izleti, šoping ture, sportska takmičenja, prevoz putnika'
        }
      ]
    }
  },
  {
    path: '/usluge_prevoza/prigradski_saobracaj',
    name: 'Prigradski_saobracaj', 
    component: () => import('../views/Prigradski_saobracaj.vue'),
    meta: {
      title: 'Domuztrans Prigradski saobraćaj',
      metaTags: [
        {
          name: 'description',
          content: 'Domuztrans održava stalne linije Prnjavor - Kulaši i Prnjavor - Ruževci u  prigradskom saobraćaju'
        },
        {
          property: 'keywords',
          content: 'Domuztrans,  prigradski saobaćaj, raspored vožnje,  Prnjavor, Kulaši, Ruževci, prevoz putnika'
        }
      ]
    }
  },
  {
    path: '/kontakt',
    name: 'Kontakt',
    component: () => import('../views/Kontakt.vue'),
    meta: {
      title: 'Domuztrans Kontakt',
      metaTags: [
        {
          name: 'description',
          content: 'Domuztrans, Kremana, Prnjavor, Republika Srpska, Bih tel. +387 51/695-112 domuztrans@gmail.com '
        },
        {
          property: 'keywords',
          content: 'Domuztrans, Kremna, Prnjavor,  kontakt, adresa, mobilni, e-mail, facebook'
        }
      ]
    }
  },
  
  {
    path: '/autobuska_stanica',
    name: 'Autobuska_stanica',
    
    component: () => import('../views/Autobuska_stanica.vue'),
    meta: {
      title: 'Domuztrans Autobuska stanica',
      metaTags: [
        {
          name: 'description',
          content: 'Autobuska stanica se nalazi na adresi Jovana Dučića bb Prnjavor.'
        },
        {
          property: 'keywords',
          content: 'Domuztrans, autobuska stanica Prnjavor'
        }
      ]
    }
  },
  {
    path: '/galerija',
    name: 'Galerija',
    
    component: () => import('../views/Galerija.vue'),
    meta: {
      title: 'Domuztrans Galerija',
      metaTags: [
        {
          name: 'description',
          content: 'Domuztrans galerija slika.'
        },
        {
          property: 'keywords',
          content: 'Domuztrans, galerija'
        }
      ]
    },


    

  },

  {
    // catch all 404 - define at the very end
    path: "*",
    component: () => import("../views/NotFound.vue")
    }
  
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  
  routes
})
/* pocetak */
// ...

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));

  next();
});

// ...
/*  kraj */


export default router
