<template>
  <v-app>
    
    
    <Navbar />
      
    <v-content>
      <router-view></router-view>
      <v-footer >
      <v-container> 
      <v-layout row wrap justify-space-around class="my-3">
        <v-flex xs10 md5>
          <v-card 
            class="mx-auto my-3 rounded-xl blue lighten-3"
            max-width="600" height="250"
          >
           <v-card-title 
              class=" 
              text-sm-body-2 
              text-md-body-1
              text-lg-h6 
              text-xl-h4
              "
              
           >
              Adresa
            </v-card-title>
            <v-card-text
            class=" 
              text-sm-body-2 
              text-md-body-1
              text-lg-h6 
              text-xl-h4
              "
              
              >
            
              <p> Kremena bb, Prnjavor </p>
              <p>Republika Srpska, BiH  </p>

              <v-row 
              >
                <v-icon x-large color="blue darken-3">phone</v-icon> <span> &#160;+ 387 51/695-112</span>
              </v-row>

            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs10 md5> 
          <v-card 
            class="mx-auto my-3 rounded-xl blue lighten-3"
            max-width="600" height="250"
            >      
            <v-card-title class=" 
              text-sm-body-2 
              text-md-body-1
              text-lg-h6 
              text-xl-h4"
             >
            <v-row align="center" justify="space-around">
             <span>e-mail</span><span> facebook </span><span> web</span>
            </v-row>
            </v-card-title>
            <v-card-text
              class=" 
              text-sm-body-2 
              text-md-body-1
              text-lg-h6 
              text-xl-h4"
              
              >
              
                
              </v-card-text>
              <v-row align="center" justify="space-around"
              >
                <v-btn fab depresed x-large  color="blue" href="mailto:domuztrans@gmail.com" >
                  <v-icon x-large>email</v-icon>
          
                </v-btn> 

                <v-btn fab depresed x-large  color="blue" href="https://facebook.com/domuztrans">
                  <v-icon x-large>facebook</v-icon>
          
                </v-btn> 

                <v-btn fab depresed x-large  color="blue"  href="http://www.domuztrans.com">
                  <v-icon x-large>web</v-icon>
                </v-btn>
              </v-row>
              <v-card-actions>

              </v-card-actions>  
          </v-card>
        </v-flex>
      </v-layout>
      <v-layout row wrap justify-center class="my-3">
        <v-flex xs10 md8 >

          <v-card 
            class="mx-auto my-3 rounded-xl grey lighten-1"
            
          >      
            
            <v-card-text class=" 
              text-xs-body-2
              text-sm-body-2 
              text-md-body-1
              text-lg-h6 
              text-xl-h4 
              blue--text lighten-3
              text-justify">
              <p class="text-center">&copy;2021 Domuztrans |&nbsp; Designed by Čedo Bunić</p>
            </v-card-text>
          </v-card>
          
          
          
        </v-flex>
      </v-layout>    
      </v-container>
            
      </v-footer>
      
    </v-content>
    
    
    
  </v-app>
</template>

<script>
import Navbar from '@/components/Navbar' 

  

export default {

 

  name: 'App',
 components: {Navbar}, 
  data: () => ({
    //
  }),
};
</script>