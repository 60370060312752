<template>
    <nav>
        
        <v-app-bar clipped-left  flat app  class="blue lighten-3" 
        
        >
            <v-toolbar-items  >
            <v-app-bar-nav-icon  light @click="drawer = !drawer">
                <v-icon x-large>mdi-menu</v-icon>
            </v-app-bar-nav-icon>
            </v-toolbar-items>
            <v-toolbar-title class="red--text text-lg-h5 text-xl-h3" >
                
               
                <span class="red--text">DOMUZTRANS</span>
                
            </v-toolbar-title>
             <v-spacer> </v-spacer>

            <!-- drp meni old -->
            <!--
            <v-menu offset-y>
                <template v-slot:activator="{ on }">
                     
                     <v-btn text v-on="on">
                        <v-icon left>expand_more</v-icon>
                        <span>MENI</span>
                    </v-btn>
                </template>
                <v-list>
                <v-list-group no-action v-for='navLink in navLinks' :key="navLink.text">
                    <v-list-item slot='activator' :to="navLink.route">
                        <v-list-item-icon>
                            <v-icon>{{ navLink.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title >{{ navLink.text }}</v-list-item-title>
                    </v-list-item>
 
                    <v-list-item v-for='sub in navLink.subLinks' :key="sub.text" :to="sub.route">
                        <v-list-item-title >{{ sub.text }}</v-list-item-title>
                    </v-list-item>
                    
                </v-list-group>
                </v-list>
            </v-menu>
            -->
            <!--  meni -->
            
            
                             
            <v-row class="hidden-sm-and-down"
                justify="space-around"
            >  
        
                <v-btn height="60"
                class=" 
              text-sm-body-2 
              text-md-body-1
              text-lg-h6 
              text-xl-h4
              "
                 text to ="/" >
                    Početna
                </v-btn>
                <v-btn height="60"
                    class=" 
              text-sm-body-2 
              text-md-body-1
              text-lg-h6 
              text-xl-h4
              "
                
                text to ="/onama" >
                    O nama
                </v-btn>

                <!-- drp meni o-->
                <v-menu  open-on-hover offset-y transition="slide-x-transition"  :close-delay="1000" bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn height="60"
                        
                        class=" 
              text-sm-body-2 
              text-md-body-1
              text-lg-h6 
              text-xl-h4
              "

                        text v-bind="attrs" v-on="on">
                            Usluge prevoza &#9660;
                            
                        </v-btn>
                    </template>
                    <v-list class=" 
              blue lighten-3">
                        <v-list-item  to="/usluge_prevoza/medjunarodni_saobracaj">
                        <v-list-item-action>
                            <v-list-item-title
                                class=" 
              text-sm-body-2 
              text-md-body-1
              text-lg-h6 
              text-xl-h4
              "
                            >Međunarodni saobraćaj</v-list-item-title>
                         </v-list-item-action>
                         </v-list-item>

                        <v-list-item  to="/usluge_prevoza/prigradski_saobracaj">
                        <v-list-item-action>
                            <v-list-item-title
                            class=" 
              text-sm-body-2 
              text-md-body-1
              text-lg-h6 
              text-xl-h4
              "
                            >Prigradski saobraćaj</v-list-item-title>
                         </v-list-item-action>
                         </v-list-item>

                        <v-list-item  to="/usluge_prevoza/vanlinijski_saobracaj">
                        <v-list-item-action>
                            <v-list-item-title
                            class=" 
              text-sm-body-2 
              text-md-body-1
              text-lg-h6 
              text-xl-h4
              "
                            >Vanlinijski saobraćaj</v-list-item-title>
                         </v-list-item-action>
                         </v-list-item>


                    </v-list>
                </v-menu>



                <v-btn height="60"
                class=" 
              text-sm-body-2 
              text-md-body-1
              text-lg-h6 
              text-xl-h4
              "
                
                 text to ="/autobuska_stanica" >
                    Autobuska stanica
                </v-btn>
                <v-btn height="60"
                
                class=" 
              text-sm-body-2 
              text-md-body-1
              text-lg-h6 
              text-xl-h4
              "

                text to ="/galerija" >
                    Galerija
                </v-btn>
                <v-btn height="60"
                
                class=" 
              text-sm-body-2 
              text-md-body-1
              text-lg-h6 
              text-xl-h4
              "
                
                text to ="/kontakt" >
                    Kontakt
                </v-btn>
            </v-row>    
            
            
            <!--  
            <v-btn text>
                <span>Sign Out</span>
                <v-icon right>exit_to_app</v-icon>
            </v-btn>

            meni -->
        </v-app-bar>


        <v-navigation-drawer   width="340" :disable-resize-watcher="true"
        
        app v-model="drawer"  class="blue lighten-3 my-14" 
        
         >
            <v-list nav >
       <div v-for="(link, i) in navLinks" :key="i">

        <v-list-item
            v-if="!link.subLinks"
            :to="link.route"
            
           
            
        >
            <v-list-item-icon>
                <v-icon>{{ link.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-title v-text="link.text" />
        </v-list-item>

        <v-list-group
            v-else
            :key="link.text"
            no-action
            
            :value="false"
        >
            <template v-slot:activator>
              
              <v-list-item-icon>
                <v-icon>{{ link.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ link.text }}</v-list-item-title>
             </template>

            <v-list-item
                v-for="sublink in link.subLinks"
                :to="sublink.route"
                :key="sublink.text"
            >
                <v-list-item-icon>
                  <v-icon>{{ sublink.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title >{{ sublink.text }}</v-list-item-title>

            </v-list-item>

        </v-list-group>

    </div>

      </v-list>
         


        </v-navigation-drawer>
        
        

    </nav>
    
    

</template>
 

<script>
export default {
  data: () => ({
    drawer: false,
    
    
    navLinks: [
      { icon: 'home', text: 'Pocetna', route: '/'},
      { icon: 'group', text: 'O nama', route: '/onama'},
      {
          icon: 'directions_bus', 
          text: 'Usluge prevoza',
         // route: '/usluge_prevoza',
          
          subLinks: [
                        {text:'Međunarodni saobraćaj', route: '/usluge_prevoza/medjunarodni_saobracaj'},
                        {text:'Prigradski saobraćaj', route: '/usluge_prevoza/prigradski_saobracaj'},
                        {text:'Vanlinijski saobraćaj', route: '/usluge_prevoza/vanlinijski_saobracaj'},
                    ]
      },
      
      
      { icon: 'departure_board', text: 'Autobuska stanica', route: '/autobuska_stanica'},
      { icon: 'photo_library', text: 'Galerija', route: '/galerija'},
      { icon: 'contact_phone', text: 'Kontakt', route: '/kontakt'},
    ]
  }),
}
</script>